<template>
  <div>
    <div-unit>
      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p
            style="font-weight: bold; margin-left: 92px; font-size: 22px"
            class="search-color"
          >
            Search for SBM Entries in Whole Database:
          </p>
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-input
            v-model="fullText"
            placeholder="Enter the keyword"
            style="width: 900px; font-size: 20px"
          >
          </el-input>
          <el-button
            class="btn-submit"
            @click="submitFullText()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
        <p style="margin-left: 92px; font-size: 18px">
          Example:
          <a
            href="/search-biomarker?api=fullText&keyword=Androgen receptor"
            hreflang="en"
            class="search-color"
            >Androgen receptor</a
          >;
          <a
            href="/search-biomarker?api=fullText&keyword=PDL1"
            hreflang="en"
            class="search-color"
            >PDL1</a
          >;
          <a
            href="/search-biomarker?api=fullText&keyword=17β-estradiol"
            hreflang="en"
            class="search-color"
            >17β-estradiol</a
          >;
          <a
            href="/search-biomarker?api=fullText&keyword=Colorectal cancer"
            hreflang="en"
            class="search-color"
            >Colorectal cancer</a
          >
          ...
        </p>
      </div>
      <!-- <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for SBM Entries by Biomarker Name:
          </p>
        </div>

        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="options1_check"
            v-model="biomarker_name"
            filterable
            default-first-option
            placeholder="Please select a biomarker name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_name_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitBiomarkerList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div> -->
      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for SBM Entries by Biomarker Group & Biomarker Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the biomarker group first, then a list of biomarker
            name under the selected class will be available.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="options1_check"
            v-model="biomarker_group"
            filterable
            default-first-option
            placeholder="Step1: Please select a biomarker group"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_group_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetBiomarkerGroupList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>

        <div style="display: flex; margin: 0 auto">
          
          <el-select
            v-loading="biomarker_group_name_options_check"
            v-model="biomarker_group_name"
            filterable
            default-first-option
            ref="searchSelect"
            placeholder="Step2: Please select a biomarker name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_group_name_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitBiomarkerGroupList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>
      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for SBM Entries by Pathway & Biomarker Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the pathway first, then a list of
            biomarker name under the selected class will be available.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="options1_check"
            v-model="pathway"
            filterable
            default-first-option
            placeholder="Step1: Please select a pathway"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in pathway_options"
              :key="index"
              :label="`${item.pathway_id}: ${item.name}`"
              :value="item.name"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetPathwayList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="pathway_name_options_check"
            v-model="pathway_name"
            filterable
            default-first-option
            ref="searchSelect"
            placeholder="Step2: Please select a biomarker name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in pathway_name_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitPathwayList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>
      <div style="display: flex; flex-direction: column; margin-bottom: 40px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for SBM Entries by Disease Class:
          </p>
        </div>

        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="options1_check"
            v-model="disease_name"
            filterable
            default-first-option
            placeholder="Please select a disease class"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in disease_name_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitDiseaseList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>
    </div-unit>

  </div>
</template>
  
  <script>
import DivUnit from "@/components/DivUnit.vue";
import api from "@/api/index";
import pathUrl from "@/api/path";
export default {
  // name: "AdvancedAnalysis",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      fullText: "",

      biomarker_name_options: [],
      disease_name_options: [],
      biomarker_group_options: [],
      biomarker_group_name_options: [],
      pathway_options: [],
      pathway_name_options: [],

      biomarker_name: "",
      disease_name: "",
      biomarker_group: "",
      biomarker_group_name: "",
      pathway: "",
      pathway_name: "",

      options1_check: true,
      biomarker_group_name_options_check: false,
      pathway_name_options_check: false,
    };
  },
  components: {
    DivUnit,
  },

  methods: {
    submitFullText() {
      this.$router.push({
        path: `/search-biomarker`,
        query: {
          api: "fullText",
          keyword: this.fullText,
        },
      });
    },
    submitBiomarkerList() {
      this.$router.push({
        path: `/search-biomarker`,
        query: {
          api: "byBiomarkerName",
          keyword: this.biomarker_name,
        },
      });
    },
    submitBiomarkerGroupList() {
      this.$router.push({
        path: `/search-biomarker`,
        query: {
          api: "byBiomarkerName",
          keyword: this.biomarker_group_name,
        },
      });
    },
    submitPathwayList() {
      this.$router.push({
        path: `/search-biomarker`,
        query: {
          api: "byBiomarkerName",
          group: this.pathway,
          keyword: this.pathway_name,
        },
      });
    },
    submitDiseaseList() {
      this.$router.push({
        path: `/search-biomarker`,
        query: {
          api: "byDisease",
          keyword: this.disease_name,
        },
      });
    },
    resetBiomarkerGroupList() {
      this.biomarker_group = "";
      this.biomarker_group_name = "";
    },
    resetPathwayList() {
      this.pathway = "";
      this.pathway_name = "";
    },
  },

  beforeMount() {},
  mounted() {
    api.getSearchBiomarkerList().then((res) => {
      this.options1_check = false;
      this.pathway_options = res.data.pathway_options;
      this.biomarker_group_options = res.data.biomarker_group_options;
      this.disease_name_options = res.data.disease_name_options;
    });
  },
  watch: {
    biomarker_group: {
      handler: function () {
        this.biomarker_group_name_options_check = true;
        this.biomarker_group_name = "";
        api
          .postClassBiomarkerList({
            data: this.biomarker_group,
          })
          .then((res) => {
            this.biomarker_group_name_options_check = false;
            this.biomarker_group_name_options =
              res.data.biomarker_group_name_options;
          });
      },
      deep: true,
    },
    pathway: {
      handler: function () {
        this.pathway_name_options_check = true;
        this.pathway_name = "";
        api
          .postPathwayBiomarkerList({
            data: this.pathway,
          })
          .then((res) => {
            this.pathway_name_options_check = false;
            this.pathway_name_options = res.data.pathway_name_options;
          });
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@color-theme: #749a5b;
@bg-theme: #f7f9f5;
@color-theme-hover: #749a5b94;

.page-title,
.search-color {
  color: @color-theme;
}

/deep/.el-button:hover,
/deep/.el-button:focus {
  color: #ffffff;
  background: @color-theme-hover;
  border-color: @color-theme-hover;
  outline: 0;
}

/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid @color-theme;
  // border-bottom-left-radius: 10px;
  // border-top-left-radius: 10px;
  // border-bottom-right-radius: 0px;
  // border-top-right-radius: 0px;
  border-radius: 0;
  outline: none;
}
/deep/.el-select:hover,
/deep/.el-range-editor.is-active,
/deep/.el-range-editor.is-active:hover,
/deep/.el-input__inner {
  border-color: @color-theme !important;
}
/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-radius: 0;
  border: 2px;
  background-color: @color-theme;
}
/deep/.div-title {
  display: none;
}
/deep/.div-unit {
  border-color: @color-theme;
}
/deep/.div-content {
  border-top: 0;
  background-color: @bg-theme;
}
</style>
  