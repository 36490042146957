import axios from 'axios'
// import { config } from 'process'
// import qs from 'qs'

const errorHandle = (status, info) => {
    switch (status) {
        case 400:
            console.log("语义有误");
            break;
        default:
            console.log(info);
            break;
    }
}


const instance = axios.create({
    // 网络请求的公共配置
    timeout: 200000,
})

// 路由请求拦截
instance.interceptors.request.use(
    config => {
        // if (config.method === "post") {
        //     config.data = qs.stringify(config.data)
        // }
        return config;
    },
    error => {
        return Promise.reject(error)
    }
)
// 路由响应拦截
instance.interceptors.response.use(
    response => {
        return response.status === 200 ? Promise.resolve(response) : Promise.reject(response);
    },
    error => {
        // const { response } = error;
        errorHandle(error.status, error.info);
        // return Promise.reject(error)
    }
)

export default instance;