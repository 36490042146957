import pathUrl from "./path"
import instance from './request'


const axios = instance;
const api = {
    // upload data =========================================================================
    getTemplateFile() {
        return axios.get(pathUrl.baseUrl + "/the_biomarker/api-template-file");
    },



    getBiomarkerInformation(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getBiomarkerInformation/" + id);
    },
    getDiseaseInformation(id) { // USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getDataDiseaseInfo/" + id);
    },
    getMicrobiotaInformation(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getMicrobiotaInformation/" + id);
    },
    getDrugInformation(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getDrugInformation/" + id);
    },
    getRiskFactorInformation(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getRiskFactorInformation/" + id);
    },


    getMoleculeExperiments(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getMoleculeExperiments/" + id);
    },
    getMoleculeBoxPlotOption(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getMoleculeBoxPlotOption/" + id);
    },
    getMoleculeBoxPlotData(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getMoleculeBoxPlotData/" + id);
    },
    getDrugBoxPlotOption(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getDrugBoxPlotOption/" + id);
    },
    getDrugBoxPlotDataGTEx(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getDrugBoxPlotDataGTEx/" + id);
    },
    getDrugBoxPlotDataTCGA(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getDrugBoxPlotDataTCGA/" + id);
    },

    getDiseaseExperiments(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getDiseaseExperiments/" + id);
    },
    getMicrobiotaExperiments(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getMicrobiotaExperiments/" + id);
    },
    getDrugExperiments(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getDrugExperiments/" + id);
    },
    getRiskFactorExperiments(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getRiskFactorExperiments/" + id);
    },
    getSRF_NTP(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSRF_NTP/" + id);
    },

    // Contact Part=====================================================================
    getRiskFactorPlot(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getRiskFactorPlot/" + id);
    },
    getDiseasePlot(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getDiseasePlot/" + id);
    },
    getSexSpecificAR(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSexSpecificAR/" + id);
    },
    getSexBiasedAR(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSexBiasedAR/" + id);
    },
    getDEGplot(id) {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getDEGplot/" + id);
    },






    // Contact Part=====================================================================
    postContactUs(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/contactus",
        });
    },



    // Search Form Page==========================================================================
    // 一级下拉
    getSearchDrugList() {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSearchDrugList");
    },
    getSearchMicrobiotaList() {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSearchMicrobiotaList");
    },
    getSearchBiomarkerList() {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSearchBiomarkerList");
    },
    getSearchFactorList() {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSearchFactorList");
    },
    getSearchDiseaseList() {// USE
        return axios.get(pathUrl.baseUrl + "/the_biomarker/getSearchDiseaseList");
    },


    // 二级下拉
    postDiseaseSeconedDropdownList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postDiseaseSeconedDropdownList",
        });
    },
    postClassDrugList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postClassDrugList",
        });
    },
    postPathwayDrugList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postPathwayDrugList",
        });
    },
    postPathwayBiomarkerList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postPathwayBiomarkerList",
        });
    },
    postClassFactorList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postClassFactorList",
        });
    },
    postClassBiomarkerList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postClassBiomarkerList",
        });
    },
    postClassMicrobiotaList(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data,
            url: pathUrl.baseUrl + "/the_biomarker/postClassMicrobiotaList",
        });
    },









    postSearchDrugResult(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/postSearchDrugResult",
        });
    },
    postSearchFactorResult(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/postSearchFactorResult",
        });
    },
    postSearchBiomarkerResult(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/postSearchBiomarkerResult",
        });
    },
    postSearchMicrobiotaResult(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/postSearchMicrobiotaResult",
        });
    },
    postSearchDiseaseResult(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/the_biomarker/postSearchDiseaseResult",
        });
    },

    // Browse Page ==========================================================================
























}

export default api;