<template>
  <div class="div-unit">
    <div class="div-title">
      <div class="div-text">
        <span v-html="icon_logo"></span>
        {{ unitTitle }}
        <span v-html="customHTML" class="div-unit-custom"></span>
      </div>
      <div
        @click="show1 = !show1"
        class="div-tool"
        title="Click to show or hide"
        style="cursor: pointer"
      >
        <i v-show="show1" class="fa fa-angle-double-down" style="font-weight: bolder;"></i>
        <i v-show="!show1" class="fa fa-angle-double-up" style="font-weight: bolder;"></i>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="show1" >
        <div class="div-content" style="padding: 20px">
          <slot></slot>
        </div>
        
      </div>
    </el-collapse-transition>
  </div>
</template>
  
  <script>
export default {
  name: "DivUnit",
  data() {
    return {
      show1: true,
    };
  },
  props: {
    unitTitle: String,
    customHTML: String,
    icon_logo: String,
    // required: true,
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.div-unit {
  // border: 3px solid #5287e3;
  border: 3px solid #2c3e50;
  margin: 0px 0 50px 0;
  // padding: 0 1%;
  // border-radius: 10px; 
  // box-shadow: 4px 4px 4px 5px #e4e8f5;
}
.div-title {
  background-color: #2c3e50;
  color: #fff;
  padding: 8px 18px;
  line-height: 1.42857;
  text-align: left;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
}
.div-text {
  display: inline-block;
}
.div-tool {
  float: right;
  display: inline-block;
  margin-right: 5px;
}
.div-content {
  padding: 25px 20px !important;
  border-top: 3px solid #2c3e50;
  // border-top: 3px solid #5287e3;
}
/deep/.el-button{
  font-size: 24px !important;
    height: 45px;
    padding: 0 20px;
    background: #5287e3;
    border-color: #5287e3;
}
/deep/.el-button:hover,
/deep/.el-button:focus{
  color: #ffffff;
    background: #d4834d;
    border-color: #d4834d;
    outline: 0px;
}
</style>
  