var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-width"},[_c('div',{class:['bioClass-part',`${_vm.activeName}-home`],staticStyle:{"margin-top":"-5px","padding-top":"40px"}},[_c('div',{class:[
        'bc-div',
        'bc5-div',
        _vm.activeName == 'Disease' || _vm.activeName == 'About'
          ? ''
          : 'deactivation',
      ],staticStyle:{"display":"flex","margin":"auto","margin-bottom":"30px","width":"96%","border-radius":"10px","border":"3px solid #c8745e"},on:{"click":function($event){_vm.activeName = 'Disease'}}},[_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"bioClass-row",staticStyle:{"margin-bottom":"0px"}},[_c('div',{},[_c('div',{class:[
            'bc-div',
            'bc3-div',
            _vm.activeName == 'Drug' || _vm.activeName == 'About'
              ? ''
              : 'deactivation',
          ],on:{"click":function($event){_vm.activeName = 'Drug'}}},[_vm._m(3),_vm._m(4)])]),_c('div',{},[_c('div',{class:[
            'bc-div',
            'bc2-div',
            _vm.activeName == 'Biomarker' || _vm.activeName == 'About'
              ? ''
              : 'deactivation',
          ],on:{"click":function($event){_vm.activeName = 'Biomarker'}}},[_vm._m(5),_vm._m(6)])]),_c('div',{},[_c('div',{class:[
            'bc-div',
            'bc4-div',
            _vm.activeName == 'Factor' || _vm.activeName == 'About'
              ? ''
              : 'deactivation',
          ],on:{"click":function($event){_vm.activeName = 'Factor'}}},[_vm._m(7),_vm._m(8)])]),_c('div',{},[_c('div',{class:[
            'bc-div',
            'bc1-div',
            _vm.activeName == 'Microbiota' || _vm.activeName == 'About'
              ? ''
              : 'deactivation',
          ],on:{"click":function($event){_vm.activeName = 'Microbiota'}}},[_vm._m(9),_vm._m(10)])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'Drug'),expression:"activeName == 'Drug'"}]},[_c('SearchDrugBlock')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'Biomarker'),expression:"activeName == 'Biomarker'"}]},[_c('SearchBiomarkerBlock')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'Factor'),expression:"activeName == 'Factor'"}]},[_c('SearchFactorBlock')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'Microbiota'),expression:"activeName == 'Microbiota'"}]},[_c('SearchMicrobiotaBlock')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'Disease'),expression:"activeName == 'Disease'"}]},[_c('SearchDiseaseBlock')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeName == 'About'),expression:"activeName == 'About'"}]},[_c('div',{staticClass:"bioClass-part"},[_c('div',{staticClass:"v-center",staticStyle:{"height":"899px","width":"1190px","display":"flex","margin":"0 auto","justify-content":"space-between","background":"url(/pic/OncoSexome-about.png)","background-size":"contain","position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","cursor":"pointer","top":"30px","height":"35px","width":"272px","right":"214px","text-align":"right"},attrs:{"title":"Click to Read OncoSexome"},on:{"click":_vm.playAudio}},[_c('img',{staticStyle:{"display":"initial","width":"35px","margin-top":"4px"},attrs:{"src":"/pic/play.png"}}),_c('audio',{ref:"audio",attrs:{"src":"/pic/OncoSexome.mp3","preload":"auto"}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-logo",staticStyle:{"height":"100px","width":"35%","justify-content":"space-around","border":"0px","border-radius":"10px"}},[_c('i',{staticClass:"icon iconfont icon-cancer1 search-page-icon2"}),_c('i',{staticClass:"icon iconfont icon-ICD-neoplasms search-page-icon2"}),_c('i',{staticClass:"icon iconfont icon-cancer2 search-page-icon2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-name",staticStyle:{"height":"100px","width":"30%","border-radius":"unset"}},[_c('div',[_vm._v("Search for Cancer"),_c('br'),_vm._v(" with Sex-based Differences")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-logo",staticStyle:{"height":"100px","width":"35%","justify-content":"space-around","border":"0px","border-radius":"10px"}},[_c('i',{staticClass:"icon iconfont icon-cancer2 search-page-icon2"}),_c('i',{staticClass:"icon iconfont icon-ICD-neoplasms search-page-icon2"}),_c('i',{staticClass:"icon iconfont icon-cancer1 search-page-icon2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-logo"},[_c('div',{staticStyle:{"margin":"auto"}},[_c('i',{staticClass:"icon iconfont icon-drug1 search-page-icon"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-name"},[_c('div',[_vm._v(" Sex-based Differences"),_c('br'),_vm._v("in Antineoplastic Drugs"),_c('br'),_vm._v(" and Response (SDR) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-logo"},[_c('div',{staticStyle:{"margin":"auto"}},[_c('i',{staticClass:"icon iconfont icon-biomarker search-page-icon"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-name"},[_c('div',[_vm._v(" Sex-based Differences"),_c('br'),_vm._v("in Oncology Relevant"),_c('br'),_vm._v("Biomarker (SBM) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-logo"},[_c('div',{staticStyle:{"margin":"auto"}},[_c('i',{staticClass:"icon iconfont icon-molecule2 search-page-icon"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-name"},[_c('div',[_vm._v(" Sex-based Differences"),_c('br'),_vm._v("in Risk Factors"),_c('br'),_vm._v("for Cancer (SRF) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-logo"},[_c('div',{staticStyle:{"margin":"auto"}},[_c('i',{staticClass:"icon iconfont icon-Microbiota1 search-page-icon"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bc-name"},[_c('div',[_vm._v(" Sex-based Differences"),_c('br'),_vm._v("in Microbial Landscape"),_c('br'),_vm._v("in Cancer Host (SML) ")])])
}]

export { render, staticRenderFns }